export const SET_ACTIVE_TASK = 'SET_ACTIVE_TASK';
export const SET_ACCESS_LAST_TASK = 'SET_ACCESS_LAST_TASK';

export const setActiveTask = task => {
  return {
    type: SET_ACTIVE_TASK,
    payload: task,
  };
};
export const setAccessLastTask = () => {
  return {
    type: SET_ACCESS_LAST_TASK,
  };
};
