import React from 'react';
import { Helmet } from 'react-helmet';

const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Helmet>
        <html lang="es" />
        <title>
          (defi)² - Discovery Driven, Empathy Enabled, Failure Fueled, Igniting
          Impact
        </title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        </style>
      </Helmet>
      {element}
    </>
  );
};

export { wrapPageElement };
