import { combineReducers } from 'redux';

import configReducer from './config.reducer';
import stepReducer from './step.reducer';
import taskReducer from './task.reducer';
import menuReducer from './menu.reducer';
import loginReducer from './login.reducer';

export const rootReducer = combineReducers({
  config: configReducer,
  step: stepReducer,
  task: taskReducer,
  menu: menuReducer,
  login: loginReducer,
});
