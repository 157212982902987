// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-antes-de-comenzar-js": () => import("./../../../src/pages/antes-de-comenzar.js" /* webpackChunkName: "component---src-pages-antes-de-comenzar-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contenido-premium-js": () => import("./../../../src/pages/contenido-premium.js" /* webpackChunkName: "component---src-pages-contenido-premium-js" */),
  "component---src-pages-contenido-pro-js": () => import("./../../../src/pages/contenido-pro.js" /* webpackChunkName: "component---src-pages-contenido-pro-js" */),
  "component---src-pages-contenidos-js": () => import("./../../../src/pages/contenidos.js" /* webpackChunkName: "component---src-pages-contenidos-js" */),
  "component---src-pages-cuenta-grupal-js": () => import("./../../../src/pages/cuenta-grupal.js" /* webpackChunkName: "component---src-pages-cuenta-grupal-js" */),
  "component---src-pages-detalle-tarea-js": () => import("./../../../src/pages/detalle-tarea.js" /* webpackChunkName: "component---src-pages-detalle-tarea-js" */),
  "component---src-pages-etapa-js": () => import("./../../../src/pages/etapa.js" /* webpackChunkName: "component---src-pages-etapa-js" */),
  "component---src-pages-glosario-js": () => import("./../../../src/pages/glosario.js" /* webpackChunkName: "component---src-pages-glosario-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investigar-mas-js": () => import("./../../../src/pages/investigar-mas.js" /* webpackChunkName: "component---src-pages-investigar-mas-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-landingold-js": () => import("./../../../src/pages/landingold.js" /* webpackChunkName: "component---src-pages-landingold-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-mi-plan-js": () => import("./../../../src/pages/mi-plan.js" /* webpackChunkName: "component---src-pages-mi-plan-js" */),
  "component---src-pages-pago-defi-js": () => import("./../../../src/pages/pago-defi.js" /* webpackChunkName: "component---src-pages-pago-defi-js" */),
  "component---src-pages-plan-inactivo-js": () => import("./../../../src/pages/plan-inactivo.js" /* webpackChunkName: "component---src-pages-plan-inactivo-js" */),
  "component---src-pages-planes-js": () => import("./../../../src/pages/planes.js" /* webpackChunkName: "component---src-pages-planes-js" */),
  "component---src-pages-registro-js": () => import("./../../../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */),
  "component---src-pages-restablecer-contrasenia-js": () => import("./../../../src/pages/restablecer-contrasenia.js" /* webpackChunkName: "component---src-pages-restablecer-contrasenia-js" */),
  "component---src-pages-splash-js": () => import("./../../../src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */),
  "component---src-pages-tarea-js": () => import("./../../../src/pages/tarea.js" /* webpackChunkName: "component---src-pages-tarea-js" */)
}

