import { createStore, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from './reducers';

const config = {
  key: 'defi',
  storage,
};

const windowGlobal = typeof window !== 'undefined' && window;
const devtools =
  process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;

const persistedReducer = persistReducer(config, rootReducer);

export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(ReduxThunk), devtools)
);
export const persistor = persistStore(store);
