import { SET_ACTIVE_TASK, SET_ACCESS_LAST_TASK } from '../actions/task';
import { LOGOUT } from '../actions/login';

const initialState = {
  activeTask: 1,
  lastTask: 41,
  lastTaskAccess: false,
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TASK:
      return {
        ...state,
        activeTask: action.payload,
      };
    case SET_ACCESS_LAST_TASK:
      return {
        ...state,
        lastTaskAccess: true,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default taskReducer;
