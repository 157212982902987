export const SET_UNLOCKED_STEPS = 'SET_UNLOCKED_STEPS';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const INIT_UNLOCKED_STEPS = 'INIT_UNLOCKED_STEPS';

export const setUnlockedSteps = (stepId, taskId) => {
  return {
    type: SET_UNLOCKED_STEPS,
    stepId,
    taskId,
  };
};

export const setActiveStep = step => {
  return {
    type: SET_ACTIVE_STEP,
    payload: step,
  };
};

export const initUnlockedSteps = unlockedSteps => {
  return {
    type: INIT_UNLOCKED_STEPS,
    payload: unlockedSteps,
  };
};
