import {
  SET_LANGUAGE,
  SET_INITIAL_VISIT,
  SET_LOGOS,
  SET_LOGOS_EMPTY,
} from '../actions/config';

const initialState = {
  language: 'ES',
  initialVisit: true,
  logos: null,
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, language: action.payload };
    case SET_INITIAL_VISIT:
      return { ...state, initialVisit: false };
    case SET_LOGOS:
      return { ...state, logos: action.payload };
    case SET_LOGOS_EMPTY:
      return { ...state, logos: null };
    default:
      return state;
  }
};

export default configReducer;
