export const SET_LAST_SUB_MENU = 'SET_LAST_SUB_MENU';

export const setLastSubMenu = (lastUid, lastId) => {
  return {
    type: SET_LAST_SUB_MENU,
    payload: {
      lastUid,
      lastId,
    },
  };
};
