import {
  SET_UNLOCKED_STEPS,
  SET_ACTIVE_STEP,
  INIT_UNLOCKED_STEPS,
} from '../actions/step';
import { LOGOUT } from '../actions/login';

const initialState = {
  unlockedSteps: [{ stepId: 1, tasks: [1] }],
  activeStep: 1,
};

// Funcion que se encarga de crear un nuevo arreglo para los pasos desbloqueados, en donde agrega
const addNewStep = (steps, stepId, newTask) => {
  const step = steps.find(step => step.stepId === stepId);
  if (step) {
    const newTasks = Array.from(new Set([...step.tasks, newTask]));
    const otherSteps = steps.filter(step => step.stepId !== stepId);
    return [{ stepId: step.stepId, tasks: newTasks }, ...otherSteps];
  } else {
    return [...steps, { stepId: stepId, tasks: [newTask] }];
  }
};

const stepReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNLOCKED_STEPS:
      return {
        ...state,
        unlockedSteps: addNewStep(
          state.unlockedSteps,
          action.stepId,
          action.taskId
        ),
      };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case INIT_UNLOCKED_STEPS:
      console.debug('se recive action.payload', action.payload);
      return {
        ...state,
        unlockedSteps: action.payload
          ? JSON.parse(action.payload)
          : initialState.unlockedSteps,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default stepReducer;
