export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_INITIAL_VISIT = 'SET_INITIAL_VISIT';
export const SET_LOGOS = 'SET_LOGOS';
export const SET_LOGOS_EMPTY = 'SET_LOGOS_EMPTY';

export const setLanguage = language => {
  console.debug('change lang');
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
};

export const setInitialVisit = () => {
  return {
    type: SET_INITIAL_VISIT,
  };
};

export const setLogos = logos => {
  return {
    type: SET_LOGOS,
    payload: logos,
  };
};

export const setLogoEmpty = logo => {
  return {
    type: SET_LOGOS_EMPTY,
  };
};
