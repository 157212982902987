export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const UPDATE_ROLE = 'UPDATE_ROLE';

export const logIn = (token, userInfo) => {
  return {
    type: LOGIN,
    payload: {
      token,
      userInfo,
    },
  };
};

export const logOut = () => {
  return {
    type: LOGOUT,
  };
};

export const updateRole = userRole => {
  return {
    type: UPDATE_ROLE,
    payload: userRole,
  };
};
