import { LOGIN, LOGOUT, UPDATE_ROLE } from '../actions/login';

const initialState = {
  isLogged: false,
  token: '',
  user: {
    username: '',
    email: '',
    role: '',
    roleName: '',
    comment: false,
    heatmap: false,
  },
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogged: true,
        token: action.payload.token,
        user: {
          username: action.payload.userInfo.username,
          email: action.payload.userInfo.email,
          role: action.payload.userInfo.role,
          roleName: action.payload.userInfo.roleName,
          comment: action.payload.userInfo.comment,
          heatmap: action.payload.userInfo.heatmap,
        },
      };
    case LOGOUT:
      return {
        ...state,
        isLogged: false,
        token: '',
        user: { username: '', email: '', role: null, roleName: null },
      };
    case UPDATE_ROLE:
      let userTmp = state.user;
      userTmp.role = action.payload.role.id;
      userTmp.roleName = action.payload.role.name;
      return {
        ...state,
        user: userTmp,
      };
    default:
      return state;
  }
};

export default loginReducer;
